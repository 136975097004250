import productFieldServices from '../../../../services/notisphere/product-field'
import { PRODUCT_FIELD_DATA_TYPE } from '../../../../misc/commons'
import TypeComponent from '../TypeComponent'
import _ from 'lodash'
export default {
    components: {
        TypeComponent
    },
    data() {
        return {
            item: {
                name: null,
                list_value: null,
                default_value: null,
                data_type: null,
            },
            formType: 'INSERT',
            productFieldDataType: PRODUCT_FIELD_DATA_TYPE,
            typeComponentKey: 0
        }
    },
    computed: {
        form(){
            if(this.formType == 'INSERT'){
                return {
                    title: 'New Product Fields',
                    disableDataType: false,
                    requiredDataType: true
                }
            }
            if(this.formType == 'UPDATE'){
                return {
                    title: 'Update Product Fields',
                    disableDataType: true,
                    requiredDataType: false
                }
            }
        }
    },
    watch: {
        'item.data_type' () {
            if (this.formType == 'INSERT') {
                this.typeComponentKey += 1
                this.item.list_value = null
                this.item.default_value = null
            }
        }
    },
    methods: {
        forceRerenderInsert() {
            this.formType = 'INSERT'
            this.$refs.modal.show()
        },
        forceRerenderUpdate(name) {
            this.formType = 'UPDATE'
            this.getProductField(name)
        },
        async save() {
            var isValid = await this.$refs.observer.validate()

            if (isValid && this.formType == 'INSERT') {
                this.createProductField()
            }
            if (isValid && this.formType == 'UPDATE'){
                this.updateProductField()
            }
        },
        updateProductField() {
            productFieldServices.updateProductField(this.item).then(resp =>{
                if (!resp.error){
                    this.$emit('onCompleted', 'UPDATE')
                    this.$refs.modal.hide()
                }
            })
        },
        createProductField() {
            productFieldServices.createProductField(this.item).then(resp =>{
                if (!resp.error){
                    this.$emit('onCompleted', 'INSERT')
                    this.$refs.modal.hide()
                }
            })
        },
        getProductField(name) {
            productFieldServices.getProductField(name).then(resp =>{
                if (!resp.error){
                    this.item = {
                        ...resp.data.d,
                        original_name: _.cloneDeep(resp.data.d.name)
                    }
                    this.$nextTick(()=>{
                        this.$refs.modal.show()
                    })
                }
            })
        }
    }
}