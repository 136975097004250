import { http, param } from '../../utils/http'
import conf from '../../config'

export const API_ROOT = `${conf.API_URL}`

const productFieldServices = {
    findProductField(payload) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'search', val: payload.search ? payload.search.trim() : '' }
        ]
        return http.get(`${API_ROOT}/notisphere/product-fields${param(arr)}`).then(resp => {
            return resp
        })
    },
    getProductField(name) {
        return http.get(`${API_ROOT}/notisphere/product-fields/${name}`).then(resp => {
            return resp
        })
    },
    createProductField(payload) {
        return http.post(`${API_ROOT}/notisphere/product-fields`, payload).then(resp => {
            return resp
        })
    },
    updateProductField(payload) {
        return http.put(`${API_ROOT}/notisphere/product-fields/${payload.original_name}`, payload).then(resp => {
            return resp
        })
    },
    deleteProductField(name) {
        return http.delete(`${API_ROOT}/notisphere/product-fields/${name}`).then(resp => {
            return resp
        })
    },
}

export default productFieldServices
