import { PRODUCT_FIELD_DATA_TYPE_OPTIONS_DATE } from '../../../../misc/commons'
import _ from 'lodash'
export default {
    props: {
        type: {type: String, default: ''},
        defaultValue: {type: Object, default: {
            valueText: null,
            valueNumber: null,
            valueDate: null,
            valueListSingle: null,
            valueListMultiple: null,
            valueYesNo: null,
            valueTrueFalse: null
        }},
        listValue: {type: Array, default: []}
    },
    data(){
        return {
            dateOptions: PRODUCT_FIELD_DATA_TYPE_OPTIONS_DATE
        }
    },
    computed: {
        _defaultValue: {
            get() {
                switch (this.type) {
                case 'Text':
                    return this.defaultValue && this.defaultValue.valueText ? this.defaultValue.valueText : null
                case 'Number':
                    return this.defaultValue && this.defaultValue.valueNumber ? this.defaultValue.valueNumber : null
                case 'Date':
                    return this.defaultValue && this.defaultValue.valueDate ? this.defaultValue.valueDate : null
                case 'YesNo':
                    return (this.defaultValue && this.defaultValue.valueYesNo === true) ? true
                        : ((this.defaultValue && this.defaultValue.valueYesNo === false) ? false : null)
                case 'TrueFalse':
                    return (this.defaultValue && this.defaultValue.valueTrueFalse === true) ? true
                        : ((this.defaultValue && this.defaultValue.valueTrueFalse === false) ? false : null)
                case 'ListSingle':
                    return this.defaultValue && this.defaultValue.valueListSingle ? this.defaultValue.valueListSingle : null
                case 'ListMultiple':
                    return this.serializeJson(this.defaultValue ? this.defaultValue.valueListMultiple: [])
                }
                return null
            },
            set(val) {
                switch (this.type) {
                case 'Text':
                    this.$emit('update:defaultValue', {valueText: val})
                    break
                case 'Number':
                    this.$emit('update:defaultValue', {valueNumber: val})
                    break
                case 'Date':
                    this.$emit('update:defaultValue', {valueDate: val})
                    break
                case 'YesNo':
                    this.$emit('update:defaultValue', {valueYesNo: val})
                    break
                case 'TrueFalse':
                    this.$emit('update:defaultValue', {valueTrueFalse: val})
                    break
                case 'ListSingle':
                    this.$emit('update:defaultValue', {valueListSingle: val})
                    break
                case 'ListMultiple':
                    this.$emit('update:defaultValue', {valueListMultiple: this.deserializeJson(val)})
                    break
                default:
                    this.$emit('update:defaultValue', null)
                    break
                }
            }
        },
        _listValue: {
            get() {
                if (['ListSingle', 'ListMultiple'].includes(this.type)) {
                    return this.serializeJson(this.listValue, 'list_value')
                }
                return []
            },
            set(val) {
                if (['ListSingle', 'ListMultiple'].includes(this.type)) {
                    this.$emit('update:listValue', this.deserializeJson(val, 'list_value'))
                } else {
                    this.$emit('update:listValue', null)
                }
            }
        },
    },
    methods: {
        deserializeJson(string, type = 'default_value') {
            try {
                if (string && string != '') {
                    string = string.trim()
                    var arr = string.split('\n')
                    if (type == 'default_value') {
                        return arr
                    } else
                    if (type == 'list_value') {
                        return arr.map( x=>{
                            return {
                                text: x,
                                value: x
                            }
                        })
                    }
                }
            } catch (e) {
                //console.log()
            }
            return null
        },
        serializeJson(json, type = 'default_value') {
            if (json && _.isArray(json) && json.length > 0) {
                var result = ''
                if (type == 'default_value') {
                    json.forEach((el, i) => {
                        result += el + (i == (json.length - 1) ? '' : '\n')
                    })
                } else
                if (type == 'list_value') {
                    json.forEach((el, i) => {
                        result += el.value + (i == (json.length - 1) ? '' : '\n')
                    })
                }
                return result
            }
            return null
        }
    }
}