import productFieldServices from '../../../services/notisphere/product-field'
import { PRODUCT_FIELD_DATA_TYPE, PRODUCT_FIELD_DATA_TYPE_OPTIONS_DATE } from '../../../misc/commons'
import ProFieModal from './ProFieModal'

export default {
    components: {
        ProFieModal
    },
    data() {
        return {
            total: 0,
            items: [],
            searchQuery: {},
            header: [{
                label: 'Name',
                name: 'name',
                sort: true,
                row_text_alignment: 'text-left',
                column_text_alignment: 'text-left'
            },
            {
                label: 'Data Type',
                name: 'data_type',
                sort: true,
                row_text_alignment: 'text-left',
                column_text_alignment: 'text-left'
            },
            {
                label: 'Default Values',
                name: 'default_value',
                row_text_alignment: 'text-left',
                column_text_alignment: 'text-left'
            },
            {
                label: 'List Values',
                name: 'list_value_preview',
                row_text_alignment: 'text-left',
                column_text_alignment: 'text-left'
            },
            {
                label: 'Last Update',
                name: 'last_change_at',
                sort: true,
                row_text_alignment: 'text-left',
                column_text_alignment: 'text-left'
            },
            {
                label: '',
                name: 'action',
                row_text_alignment: 'text-center',
                column_text_alignment: 'text-center'
            }
            ],
            proFieModalKey: 0,
            productFieldDataType: PRODUCT_FIELD_DATA_TYPE,
            productFieldDataTypeOptionDate: PRODUCT_FIELD_DATA_TYPE_OPTIONS_DATE,
        }
    },
    methods: {
        onCompleted(type) {
            if (type == 'UPDATE') {
                this._showToast('Updated successfully!')
            }
            if (type == 'INSERT') {
                this._showToast('Added successfully!')
            }
            this.fetchData()
        },
        addMoreClick() {
            this.proFieModalKey += 1
            this.$nextTick(()=>{
                this.$refs.proFieModal.forceRerenderInsert()
            })
        },
        updateProFie(payload) {
            this.proFieModalKey += 1
            this.$nextTick(()=>{
                this.$refs.proFieModal.forceRerenderUpdate(payload.name)
            })
        },
        fetchDelay() {
            this.$refs.table.fetchDelay()
        },
        fetchData() {
            productFieldServices.findProductField({
                ...this.searchQuery,
                sortField: this.searchQuery.sort[0] ? this.searchQuery.sort[0].name : null,
                sortDirection: this.searchQuery.sort[0] ? this.searchQuery.sort[0].order : null
            }).then(resp=>{
                if(!resp.error) {
                    this.items = resp.data.d.c
                    this.total = resp.data.d.t
                }
            })
        },
        deleteProductField(item, e) {
            this._showConfirm('You are about to delete this product field, this cannot be undone.').then(confirm=>{
                if(confirm) {
                    productFieldServices.deleteProductField(item.name).then(resp=>{
                        if (!resp.error) {
                            this.onCompleted('UPDATE')
                        }
                    })
                }
            })
            e.stopPropagation()
        }
    }
}
